import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, Link, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6, 2),
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  stdLink: {
    color: theme.palette.spiga.typos.color,
    paddingRight: '15px',
    textDecoration: 'none',
  },
  stdLinkRight: {
    color: theme.palette.spiga.typos.color,
    paddingLeft: '15px',
    textDecoration: 'none',
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  footerLogo: {
    transformOrigin: '100% 50%',
    transform: 'scaleY(.5) scaleX(.5)',
    opacity: 0.5,
  }
}));

function Footer() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.footer}>
      <Divider className={classes.divider} />
      <Link
        align="right"
        color="inherit"
        href={`mailto:${theme.config.mail}`}
        underline="always"
        variant="subtitle2"
        target="_blank"
        className={classes.stdLink}
      >
        {theme.config.strings.helpLabel}
      </Link>
      <Link
        align="right"
        color="inherit"
        href={theme.config.mainSiteUrl}
        underline="always"
        variant="subtitle2"
        target="_blank"
        className={classes.stdLinkRight}
      >
        <img
          alt="Logo"
          src="/images/logos/logo.svg"
          className={classes.footerLogo}
        />
      </Link>
    </div>
  );
}

export default Footer;
